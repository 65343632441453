@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.boxShadow {
  -webkit-box-shadow: 5px 2px 15px 1px rgba(0,0,0,0.35); 
  box-shadow: 5px 2px 15px 1px rgba(0,0,0,0.35);
}

.boxShadowL {
  -webkit-box-shadow: 15px 0px 11px -4px rgba(0,0,0,0.47); 
  box-shadow: 15px 0px 11px -4px rgba(0,0,0,0.47);
}

.dark {
  background: var(--secondaryDarkColor);
}

.Right_To_Center {
  animation-name: Right_To_Center;
  animation-duration: 0.2s;
}

@keyframes Right_To_Center {
  from {
    transform: translateX(12px);
  }
  to {
    transform: translateX(0);
  }
}